import { gql } from 'graphql-request';

import {
  accordionBlockFragment,
  articleBlockFragment,
  articlesLatestBlockFragment,
  assetFragment,
  cardsBlockFragment,
  caseBlockFragment,
  caseSelectionBlockFragment,
  columnsBlockFragment,
  headingBlockFragment,
  heroBlockFragment,
  imagesBlockFragment,
  lineBlockFragment,
  overviewBlockFragment,
  picturesBlockFragment,
  slugFragment,
  teamBlockFragment,
  textBlockFragment,
  textImageBlockFragment,
} from '../fragments';

export const pageQuery = gql`
  query Page($locale: String!, $slug: String!) {
    pages: allPage(
      where: { i18n_lang: { eq: $locale }, slug: { current: { eq: $slug } } }
    ) {
      _id
      author {
        avatar {
          asset {
            url
          }
          crop {
            bottom
            left
            right
            top
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        name
        position
      }
      blocks {
        ...accordionBlock
        ...alternateBlock
        ...articleBlock
        ...articlesLatestBlock
        ...cardsBlock
        ...caseBlock
        ...caseSelectionBlock
        ...columnsBlock
        ...contactBlock
        ...headingBlock
        ...heroBlock
        ...imagesBlock
        ...lineBlock
        ...listBlock
        ...overviewBlock
        ...picturesBlock
        ...quoteBlock
        ...teamBlock
        ...textBlock
        ...textImageBlock
        ...traineeshipApplicationBlock
      }
      client
      description
      i18n_lang
      i18n_base {
        title
      }
      i18n_refs {
        title
      }
      image {
        alt
        ...asset
      }
      latestUpdateDateTime
      parentPage {
        _id
        i18n_lang
        ...slug
      }
      publicationDateTime
      ...slug
      subtitle
      title
      type
    }
  }

  ${accordionBlockFragment}
  ${articleBlockFragment}
  ${articlesLatestBlockFragment}
  ${cardsBlockFragment}
  ${caseBlockFragment}
  ${caseSelectionBlockFragment}
  ${columnsBlockFragment}
  ${headingBlockFragment}
  ${heroBlockFragment}
  ${lineBlockFragment}
  ${imagesBlockFragment}
  ${overviewBlockFragment}
  ${picturesBlockFragment}
  ${teamBlockFragment}
  ${textBlockFragment}
  ${textImageBlockFragment}

  ${assetFragment}
  ${slugFragment}
`;
