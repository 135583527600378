import { gql } from 'graphql-request';

export const accordionBlockFragment = gql`
  fragment accordionBlock on AccordionBlock {
    __typename
    _key
    accordionItems {
      _key
      subtitle
      text
      title
    }
  }
`;
