import { ChangeEvent, MouseEvent, ReactNode } from 'react';

import * as Styled from './InquiryDialogAnswer.styled';

interface InquiryDialogAnswerProps {
  children: ReactNode;
  id: string;
  name: string;
  onSelect: (args?: unknown) => unknown;
  value: string;
}

export const InquiryDialogAnswer = ({
  children,
  id,
  name,
  onSelect,
  value,
}: InquiryDialogAnswerProps) => (
  <Styled.Label htmlFor={id}>
    <Styled.Input
      id={id}
      name={name}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const nativeEvent =
          event.nativeEvent as unknown as MouseEvent<HTMLInputElement>;
        if (nativeEvent.clientX !== 0 && nativeEvent.clientY !== 0) {
          onSelect();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.code === 'Space') {
          onSelect();
          event.preventDefault();
        }
      }}
      type="radio"
      value={value}
    />
    {children}
  </Styled.Label>
);
