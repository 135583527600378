import { gql } from 'graphql-request';

export const assetFragment = gql`
  fragment asset on A11YImage {
    asset {
      metadata {
        lqip
      }
      url
    }
  }
`;
