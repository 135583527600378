import styled, { css } from 'styled-components';

import { Container as ContainerBase } from '@humanoids/ui';

export const NavigationExpansion = styled.div<{
  isExpanded: boolean;
  isVisible: boolean;
}>(
  ({ isExpanded, isVisible, theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    bottom: 0;
    cursor: auto;
    display: none;
    font-size: 16px;
    left: calc(calc(100vw - 100%) / 2 * -1);
    pointer-events: ${isVisible ? 'auto' : 'none'};
    position: absolute;
    transform: translateY(${isExpanded ? '100%' : '0'});
    transition: ${isExpanded
      ? 'transform 0.2s ease-in-out'
      : 'transform 0.2s ease-in-out, visibility 0.1s ease-in-out'};
    visibility: ${isVisible ? 'visible' : 'hidden'};
    width: 100vw;
    z-index: -1;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: block;
    }
  `
);

export const Container = styled(ContainerBase)`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 var(--spacing) 32px var(--spacing);
`;
