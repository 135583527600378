import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

import { ConfigurationType, PageType } from '../../services/graphql';

type SubstoreType = {
  [substore: string]: unknown;
  articles?: PageType[];
  configuration?: ConfigurationType[];
  inquiryDialogVisibility?: any;
  translationMetadata?: {
    schemaTypes: string[];
    translations: { value: PageType }[];
  }[];
};

type StoreContextType =
  | [SubstoreType, Dispatch<SetStateAction<SubstoreType>>]
  | [SubstoreType];

export const StoreContext = createContext<StoreContextType>([{}]);

export const StoreProvider = ({ children, ...props }) => {
  const [store, setStore] = useState(props);

  // TODO: Temporary workaround to update store manually
  useEffect(() => {
    if (JSON.stringify(props) === JSON.stringify(store)) {
      return;
    }

    setStore(props);
  }, [props]);

  return (
    <StoreContext.Provider value={[store, setStore]}>
      {children}
    </StoreContext.Provider>
  );
};
