import {
  ComponentType,
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from 'react';

type ExpansionType = string | null;

interface NavigationContextInterface {
  expansion:
    | [ExpansionType, Dispatch<SetStateAction<ExpansionType>>]
    | [ExpansionType];
  mobile: [boolean, Dispatch<SetStateAction<boolean>>] | [boolean];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NavigationContext = createContext<NavigationContextInterface>({
  expansion: [null],
  mobile: [false],
});
NavigationContext.displayName = 'NavigationContext';

export const NavigationContextProvider = ({ children }) => {
  const [activeId, setActiveId] = useState<ExpansionType>(null);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <NavigationContext.Provider
      value={{
        expansion: [activeId, setActiveId],
        mobile: [isVisible, setIsVisible],
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const withNavigationContext = <P extends object>(
  Component: ComponentType<P>
) =>
  function NavigationContextHoc(props: P) {
    return (
      <NavigationContextProvider>
        <Component {...props} />
      </NavigationContextProvider>
    );
  };
