import styled, { css } from 'styled-components';

export const Fieldset = styled.fieldset(
  ({ theme }) => css`
    background: none;
    border: none;
    display: grid;
    gap: 16px;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 calc(var(--spacing) * 2);
    }
  `
);
