import { gql } from 'graphql-request';

export const columnsBlockFragment = gql`
  fragment columnsBlock on ColumnsBlock {
    __typename
    _key
    columns {
      ...articlesLatestBlock
      ...cardsBlock
      ...caseSelectionBlock
      ...headingBlock
      ...imagesBlock
      ...teamBlock
      ...textBlock
    }
  }
`;
