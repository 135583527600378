import { gql } from 'graphql-request';

export const cardsBlockFragment = gql`
  fragment cardsBlock on CardsBlock {
    __typename
    _key
    cards {
      _key
      cta
      image {
        alt
        ...asset
      }
      page {
        i18n_lang
        parentPage {
          type
          i18n_lang
          slug {
            current
          }
        }
        slug {
          current
        }
      }
      position
      text
      title
      url
    }
    size
  }
`;
