import { useContext, useState } from 'react';
import { useRouter } from 'next/compat/router';

import { parseMarkdown, Text } from '@humanoids/ui';

import { useGeneratePath } from '@/hooks/use-generate-path';
import { NavigationCardType } from '@/services';
import { NavigationContext } from '../navigation-context-provider';

import * as Styled from './NavigationCard.styled';

interface NavigationCardProps {
  card: NavigationCardType;
}

export const NavigationCard = ({ card }: NavigationCardProps) => {
  const [, setActiveId] = useContext(NavigationContext).expansion;
  const [highlight, setHighlight] = useState(false);
  const generatePath = useGeneratePath();
  const router = useRouter();

  return (
    <Styled.NavigationCard alternate={card.alternate} highlight={highlight}>
      <Styled.Header
        onClick={() => {
          router.push(generatePath(card.page));
          setActiveId(null);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            router.push(generatePath(card.page));
            setActiveId(null);
          }
        }}
        onMouseOver={() => setHighlight(true)}
        onMouseLeave={() => setHighlight(false)}
        role="link"
        tabIndex={0}
      >
        <Styled.Heading quinary role="link">
          {card.title}
        </Styled.Heading>
        <Styled.Description>
          <Text
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(card.description ?? ''),
            }}
            small
          />
        </Styled.Description>
      </Styled.Header>
      <Styled.NavigationItems>
        {card.links?.map(({ _key, page, title, url }) => {
          const path = page ? generatePath(page) : url ?? '#';

          return (
            <Styled.Background key={_key}>
              <Styled.NavigationItem
                href={path}
                onClick={() => setActiveId(null)}
                prefetch={false}
              >
                {title} <Styled.Arrow />
              </Styled.NavigationItem>
            </Styled.Background>
          );
        })}
      </Styled.NavigationItems>
    </Styled.NavigationCard>
  );
};
