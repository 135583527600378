import * as Styled from './Heading.styled';
import * as Types from './Heading.types';

export const Heading = ({
  children,
  primary,
  secondary,
  tertiary,
  quaternary,
  quinary,
  ...props
}: Types.HeadingProps) => (
  <>
    {(primary || (!secondary && !tertiary && !quaternary && !quinary)) && (
      <Styled.HeadingPrimary as="h1" {...props}>
        {children}
      </Styled.HeadingPrimary>
    )}
    {secondary && (
      <Styled.HeadingSecondary as="h2" {...props}>
        {children}
      </Styled.HeadingSecondary>
    )}
    {tertiary && (
      <Styled.HeadingTertiary as="h3" {...props}>
        {children}
      </Styled.HeadingTertiary>
    )}
    {quaternary && (
      <Styled.HeadingQuaternary as="h4" {...props}>
        {children}
      </Styled.HeadingQuaternary>
    )}
    {quinary && (
      <Styled.HeadingQuinary as="h5" {...props}>
        {children}
      </Styled.HeadingQuinary>
    )}
  </>
);
