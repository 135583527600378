import imageBuilder from '@sanity/image-url';
import { SanityProjectDetails } from '@sanity/image-url/lib/types/types';

const sanityProjectDetails = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
};

const fileBuilder = ({ dataset, projectId }: SanityProjectDetails) => ({
  file: (ref: string) => {
    const [, id, extension] = ref.split('-');
    return `https://cdn.sanity.io/files/${projectId}/${dataset}/${id}.${extension}`;
  },
});

export const fileUrlBuilder = fileBuilder(sanityProjectDetails);

export const imageUrlBuilder = imageBuilder(sanityProjectDetails);
