import { useContext } from 'react';

import { NavigationContext } from '../navigation-context-provider';

import * as Styled from './NavigationMobileToggle.styled';

export const NavigationMobileToggle = () => {
  const [isVisible, setIsVisible] = useContext(NavigationContext).mobile;

  // TODO: Make `aria-label` translatable
  return (
    <Styled.NavigationMobileToggle
      aria-label="Navigation"
      onClick={() => {
        setIsVisible(!isVisible);
      }}
    >
      {isVisible && <Styled.CloseIcon />}
      {!isVisible && <Styled.NavigationIcon />}
    </Styled.NavigationMobileToggle>
  );
};
