import { useIntl } from 'react-intl';
import { useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { Button } from '@humanoids/ui';
import { ReactComponent as CloseIcon } from '@/images/cross.svg';

import * as Styled from './InquiryCta.styled';

export const InquiryCta = () => {
  const [isCtaVisible, setIsCtaVisible] = useState(true);
  const pathname = usePathname();
  const router = useRouter();
  const intl = useIntl();

  if (!isCtaVisible) {
    return null;
  }

  return (
    <Styled.InquiryCta
      forwardedAs="a"
      onClick={() => router.push(`${pathname}?inquiry=true`, { scroll: false })}
    >
      <Styled.DoodlesLeft />
      <Styled.DoodlesRight />
      <Styled.Desktop>
        {intl.formatMessage({
          id: 'inquiryCtaIntroDesktop',
        })}
      </Styled.Desktop>
      <Styled.Mobile>
        {intl.formatMessage({
          id: 'inquiryCtaIntroMobile',
        })}
      </Styled.Mobile>
      <Button secondary>
        <Styled.Desktop>
          {intl.formatMessage({
            id: 'inquiryCtaButtonDesktop',
          })}
        </Styled.Desktop>
        <Styled.Mobile>
          {intl.formatMessage({
            id: 'inquiryCtaButtonMobile',
          })}
        </Styled.Mobile>
      </Button>
      <Styled.ManHoldingSignIllustration />
      <Styled.CloseButton
        aria-label={intl.formatMessage({
          id: 'close',
        })}
        onClick={(event) => {
          setIsCtaVisible(false);
          event.stopPropagation();
        }}
        quaternary
      >
        <CloseIcon />
      </Styled.CloseButton>
    </Styled.InquiryCta>
  );
};
