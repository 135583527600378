import styled, { css } from 'styled-components';

export const Label = styled.label(
  ({ theme }) => css`
    color: ${theme.brandColors.secondary.dark};
    display: grid;
    font-size: 16px;
    gap: 8px;
    line-height: 16px;
  `
);
