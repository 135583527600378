import { Highlight, Language, themes } from 'prism-react-renderer';

import * as Styled from './Code.styled';

interface CodeProps {
  code: string;
  language: Language;
}

export const Code = ({ code, language }: CodeProps) => (
  <Styled.Code>
    <Highlight
      code={code}
      language={language}
      theme={themes.oceanicNext}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  </Styled.Code>
);
