import styled, { css } from 'styled-components';
import Link from 'next/link';

interface NavigationItemLinkProps {
  active: boolean;
}

export const NavigationItems = styled.ul(
  ({ theme }) => css`
    display: none;
    flex-direction: row;
    list-style-type: none;
    gap: 24px;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: flex;
      justify-content: flex-end;
    }

    &:first-of-type {
      justify-content: flex-start;
    }
  `
);

export const NavigationItemLink = styled(Link).withConfig({
  shouldForwardProp: (prop: string) => !['active'].includes(prop),
})<NavigationItemLinkProps>(
  ({ active, theme }) => css`
    color: ${active ? theme.brandColors.primary.main : theme.textColor.primary};
    text-decoration: none;
  `
);
