import styled, { css } from 'styled-components';

export const Answers = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 32px;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }
  `
);
