import styled, { css } from 'styled-components';
import Link from 'next/link';

import { darken, Heading as HeadingBase } from '@humanoids/ui';

import { ReactComponent as ArrowSvg } from '@/images/arrow.svg';

interface NavigationCardProps {
  alternate?: boolean;
  highlight?: boolean;
}

export const NavigationCard = styled.div<NavigationCardProps>(
  ({ alternate, highlight, theme }) => css`
    background-color: ${alternate
      ? 'transparent'
      : theme.backgroundColor.primary};
    cursor: pointer;
    display: grid;
    grid-auto-rows: min-content;

    ${highlight &&
    css`
      background-color: ${darken(theme.backgroundColor.primary, 2)};

      h4 {
        color: ${theme.brandColors.primary.main};
      }
    `}
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: 16px;
    grid-template-rows: repeat(2, min-content);
    padding: 16px 16px 0 16px;

    &:after {
      background-color: #d8d8d8;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
    }

    &:focus-visible {
      outline: 3px solid ${theme.brandColors.primary.main};

      h5 {
        color: ${theme.brandColors.primary.main};
      }
    }
  `
);

export const Heading = styled(HeadingBase)`
  font-weight: bold;
  white-space: pre-line;
`;

export const Description = styled.div`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const NavigationItems = styled.div`
  &:has(:nth-child(2)) {
    margin-bottom: -8px;
  }

  &:has(:nth-child(3)) {
    margin-bottom: -16px;
  }

  &:has(:nth-child(4)) {
    margin-bottom: -24px;
  }

  &:has(:nth-child(5)) {
    margin-bottom: -32px;
  }
`;

export const Background = styled.div(
  ({ theme }) => css`
    background-color: transparent;

    &:nth-child(2) {
      margin-bottom: 8px;
      margin-top: -8px;
    }

    &:nth-child(3) {
      margin-bottom: 16px;
      margin-top: -16px;
    }

    &:nth-child(4) {
      margin-bottom: 24px;
      margin-top: -24px;
    }

    &:nth-child(5) {
      margin-bottom: 32px;
      margin-top: -32px;
    }

    &:hover {
      background-color: ${darken(theme.backgroundColor.primary, 4)};
    }
  `
);

export const Arrow = styled(ArrowSvg)`
  display: block;
  height: 8px;
  position: relative;
  width: 12px;

  svg {
    left: 0;
    position: absolute;
    top: 0;
  }
`;

export const NavigationItem = styled(Link)(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.textColor.primary};
    display: grid;
    grid-template-columns: 1fr auto;
    font-weight: bold;
    padding: 16px;
    position: relative;
    text-decoration: none;

    &:focus,
    &:hover {
      color: ${theme.brandColors.primary.main};

      svg {
        fill: ${theme.brandColors.primary.main};
      }
    }
  `
);
