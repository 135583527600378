import { useContext } from 'react';

import { StoreContext } from '../../components/store-provider';
import { PageType, generatePath } from '../../services';
import { useRouter } from 'next/compat/router';

export const useGeneratePath = () => {
  const router = useRouter();
  const defaultLanguage = router.defaultLocale;

  const [{ configuration }] = useContext(StoreContext);

  return (page: PageType): string => {
    return `/${
      page.i18n_lang === defaultLanguage ? '' : `${page.i18n_lang}/`
    }${generatePath(page, configuration).join('/')}`;
  };
};
