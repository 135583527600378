import { RefObject, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/compat/router';

import {
  parseMarkdown,
  useBodyScrollBlock,
  useTrueViewportHeight,
} from '@humanoids/ui';

import { NavigationContext } from '../navigation-context-provider';
import { NavigationItem } from '../navigation-item';
import { NavigationSecondary } from '../navigation-secondary';
import { useGeneratePath } from '@/hooks/use-generate-path';
import { NavigationItemType } from '@/services';

import * as Styled from './NavigationMobile.styled';

interface NavigationMobileProps {
  navigationItems: NavigationItemType[];
  navigationRef: RefObject<HTMLElement>;
}

export const NavigationMobile = ({
  navigationItems,
  navigationRef,
}: NavigationMobileProps) => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useContext(NavigationContext).mobile;
  const [offsetTop, setOffsetTop] = useState(0);
  const [activeNavigationItem, setActiveNavigationItem] = useState(
    navigationItems[0]._key
  );
  const generatePath = useGeneratePath();

  const toggleBodyScrollBlock = useBodyScrollBlock();
  useTrueViewportHeight();

  useEffect(() => {
    toggleBodyScrollBlock(isVisible);

    const { top } = navigationRef.current.getBoundingClientRect();
    setOffsetTop(top);

    return () => toggleBodyScrollBlock(isVisible);
  }, [isVisible]);

  return (
    <Styled.NavigationMobile isVisible={isVisible} offsetTop={offsetTop}>
      {navigationItems.map(({ _key, cards, page, title, url }) => {
        const path = page ? generatePath(page) : url ?? '#';

        return (
          <NavigationItem
            active={activeNavigationItem === _key}
            expandable={cards?.length > 0}
            key={_key}
          >
            <Styled.NavigationItemLink
              active={activeNavigationItem === _key}
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(title),
              }}
              href={path}
              onClick={(event) => {
                event.preventDefault();

                if (!cards?.length || activeNavigationItem === _key) {
                  router.push(path);
                  setIsVisible(false);
                } else {
                  setActiveNavigationItem(_key);
                }
              }}
            />
            {cards?.length > 0 && (
              <Styled.CardLinks
                active={_key === activeNavigationItem}
                cardLinksLength={cards.length}
              >
                {cards.map(({ _key, page, title, url }) => {
                  const path = page ? generatePath(page) : url ?? '#';

                  return (
                    <li key={_key}>
                      <Styled.CardLink
                        href={path}
                        onClick={(event) => {
                          event.preventDefault();

                          router.push(path);
                          setIsVisible(false);
                        }}
                      >
                        {title}
                      </Styled.CardLink>
                    </li>
                  );
                })}
              </Styled.CardLinks>
            )}
          </NavigationItem>
        );
      })}
      <li>
        <NavigationSecondary />
      </li>
    </Styled.NavigationMobile>
  );
};
