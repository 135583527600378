import styled, { css } from 'styled-components';

import { Container as ContainerBase, theme } from '@humanoids/ui';

import { ReactComponent as HumanoidsLogoSvg } from '../../public/images/humanoids-logo.svg';

export const Footer = styled.footer(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.tertiary};

    /* Target [href] to work around the specificity in the Text component */
    a[href] {
      color: ${theme.brandColors.primary.main};
    }
  `
);

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    color: ${theme.textColor.secondary};
    column-gap: var(--spacing);
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    padding: var(--spacing);

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-auto-flow: column;
      grid-template-columns: 1fr auto auto;
      padding-bottom: 128px;
      padding-top: 128px;
    }

    @media (min-width: ${theme.breakpoints.large}) {
      grid-template-columns: calc(50% - var(--spacing)) 1fr 1fr;
    }
  `
);

export const HumanoidsLogo = styled(HumanoidsLogoSvg)(
  ({ theme }) => css`
    height: 20px;
    fill: #b4b4b4;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  `
);

export const Accreditations = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-row-start: 14;
    margin-top: 40px;
    row-gap: 16px;

    @media (min-width: ${theme.breakpoints.medium}) {
      align-self: end;
      grid-column-start: 1;
      grid-row-end: span 2;
      grid-row-start: 1;
      margin-top: 0;
    }

    p {
      line-height: 32px;
    }
  `
);

export const Awards = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, min-content);
`;

export const Heading = styled.div(
  ({ theme }) => css`
    font-size: 16px;
    line-height: 28px;

    @media (max-width: ${theme.breakpoints.medium}) {
      margin-top: 40px;
    }
  `
);

export const Location = styled.div(
  ({ theme }) => css`
    grid-column-start: 1;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-column-start: 2;

      & + & {
        grid-column-start: 3;
      }
    }
  `
);

export const Address = styled.address`
  font-style: normal;
  margin-top: 12px;
`;

export const photoMediaCondition = `
  (max-width: ${theme.breakpoints.medium}) 100vw,
  252px
`;

export const Photo = styled.div(
  ({ theme }) => css`
    height: 189px;
    margin-top: 24px;
    position: relative;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 24px 0 68px;
      width: 252px;
    }

    img {
      object-fit: cover;
    }
  `
);

export const Contact = styled.address(
  ({ theme }) => css`
    align-self: end;
    font-style: normal;
    grid-row-start: 2;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-column-start: 2;
      grid-row-start: initial;
    }
  `
);

export const Socials = styled.div(
  ({ theme }) => css`
    grid-row-start: 3;

    @media (min-width: ${theme.breakpoints.medium}) {
      align-self: end;
      grid-column-start: 3;
      grid-row-start: initial;
    }

    img {
      display: inline-block;
      fill: #b4b4b4;
      height: 22px;
      margin-right: 8px;
      vertical-align: middle;
    }
  `
);
