import styled, { css } from 'styled-components';

import { Text as TextBase } from '../text';
import { ReactComponent as ChevronIconSvg } from '../../images/chevron-right.svg';

export const Button = styled.div<{
  disabled: boolean | undefined;
  quaternary: boolean | undefined;
  secondary: boolean | undefined;
  tertiary: boolean | undefined;
}>(
  ({ disabled, quaternary, secondary, tertiary, theme }) => css`
    all: unset;
    align-items: center;
    appearance: none;
    background:
      linear-gradient(#fff, #fff) padding-box,
      ${theme.gradient} border-box;
    border-radius: 50vh;
    border: 2px solid transparent;
    color: ${theme.textColor.primary};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    font-weight: 700;
    gap: 5px;
    justify-content: space-between;
    overflow: hidden;
    padding: 12px 8px 12px 18px;
    position: relative;
    width: fit-content;

    a:has(&) {
      text-decoration: none;
    }

    a:has(&):hover & {
      background:
        linear-gradient(hsl(0, 0%, 89%), hsl(0, 0%, 89%)) padding-box,
        ${theme.gradient} border-box;
    }

    ${disabled &&
    css`
      background: ${theme.backgroundColor.quaternary};
    `}

    ${secondary &&
    css`
      background: ${theme.brandColors.primary.light};

      a:has(&):hover & {
        background: ${theme.brandColors.primary.main};
      }
    `}

    ${tertiary &&
    css`
      background: ${theme.brandColors.secondary.light};

      a:has(&):hover &,
      &:hover {
        background: ${theme.brandColors.secondary.main};
      }
    `}


    ${quaternary &&
    css`
      border: none;
      background: transparent;
      padding: 12px 18px;

      a:has(&):hover &,
      &:hover {
        background: hsl(0, 0%, 89%);
      }

      & > svg {
        display: none;
      }
    `}
  `
);

export const Text = styled(TextBase)(
  ({ theme }) => css`
    margin-left: 6px;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-left: 8px;
    }
  `
);

export const ChevronIcon = styled(ChevronIconSvg)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.medium}) {
      height: 28px;
      width: 28px;
    }
  `
);
