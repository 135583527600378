import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { NavigationContext } from '../navigation-context-provider';
import { NavigationItems } from '../navigation-items';
import { NavigationMobile } from '../navigation-mobile';
import { NavigationMobileToggle } from '../navigation-mobile-toggle';
import { NavigationSecondary } from '../navigation-secondary';
import { withNavigationContext } from '../navigation-context-provider';
import { NavigationItemType } from '@/services';

import * as Styled from './Navigation.styled';

interface NavigationProps {
  navigationItems: NavigationItemType[];
}

export const Navigation = withNavigationContext(function Navigation({
  navigationItems,
}: NavigationProps) {
  const [, setActiveId] = useContext(NavigationContext).expansion;
  const [, setIsVisible] = useContext(NavigationContext).mobile;
  const ref = useRef<HTMLElement>();
  const intl = useIntl();

  const keyDownHandler = (event) => {
    if (event.key === 'Escape') {
      setActiveId(null);
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', keyDownHandler);

    return () => {
      document.body.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>
      <NavigationSecondary />
      <Styled.Navigation onMouseLeave={() => setActiveId(null)} ref={ref}>
        <NavigationMobile
          navigationItems={navigationItems}
          navigationRef={ref}
        />
        <Styled.Backdrop>
          <Styled.Container>
            <NavigationMobileToggle />
            <NavigationItems
              navigationItems={navigationItems.filter(
                ({ align }) => align === 'left'
              )}
            />
            <Styled.Link
              aria-label={intl.formatMessage({
                id: 'homepage',
              })}
              href="/"
              onClick={() => setIsVisible(false)}
              prefetch={false}
            >
              <Styled.Logo />
            </Styled.Link>
            <NavigationItems
              navigationItems={navigationItems.filter(
                ({ align }) => align === 'right'
              )}
            />
          </Styled.Container>
        </Styled.Backdrop>
      </Styled.Navigation>
    </>
  );
});
