import { useContext } from 'react';
import { useRouter } from 'next/compat/router';

import { parseMarkdown } from '@humanoids/ui';

import { NavigationContext } from '../navigation-context-provider';
import { NavigationItem } from '../navigation-item';
import { StoreContext } from '../store-provider';
import { NavigationItemType, generatePath } from '@/services';

import * as Styled from './NavigationItems.styled';

interface NavigationItemsProps {
  navigationItems: NavigationItemType[];
}

export const NavigationItems = ({ navigationItems }: NavigationItemsProps) => {
  const [activeId, setActiveId] = useContext(NavigationContext).expansion;
  const [{ configuration }] = useContext(StoreContext);
  const router = useRouter();

  return (
    <Styled.NavigationItems>
      {navigationItems.map(({ _key, cards, page, title, url }) => {
        const path = page
          ? `/${generatePath(page, configuration).join('/')}`
          : url ?? '#';

        return (
          <NavigationItem
            _key={_key}
            active={activeId === _key}
            aria-expanded={activeId === _key}
            cards={cards}
            expandable={cards?.length > 0}
            key={_key}
            title={title}
          >
            <Styled.NavigationItemLink
              active={activeId === _key}
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(title),
              }}
              href={path}
              onClick={(event) => {
                event.preventDefault();
                router.push(path);
                setActiveId(null);
              }}
              onMouseOver={() => setActiveId(_key)}
              prefetch={false}
            />
          </NavigationItem>
        );
      })}
    </Styled.NavigationItems>
  );
};
