import styled, { css } from 'styled-components';
import Link from 'next/link';

import { Container as ContainerBase } from '@humanoids/ui';

export const NavigationSecondary = styled.nav(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    padding-bottom: var(--spacing);
    position: relative;
    // Required due to Safari z-index bug when scrolling:
    transform: translateZ(1px);
    width: 100vw;
    z-index: 110;

    #__next div > & {
      display: none;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      padding-bottom: 0;

      #__next div > & {
        display: block;
      }
    }
  `
);

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: row;
    justify-content: start;
    padding: 16px var(--spacing) 0 var(--spacing);

    @media (min-width: ${theme.breakpoints.medium}) {
      gap: 16px;
      grid-auto-flow: column;
      justify-content: end;
    }
  `
);

export const NavigationItem = styled(Link)(
  ({ theme }) => css`
    color: ${theme.textColor.primary};
    cursor: pointer;
    font-size: 16px;
    padding: 8px 0;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 14px;
    }
  `
);
