import styled, { css } from 'styled-components';

interface BadgeProps {
  primary?: boolean;
  secondary?: boolean;
}

export const Badge = styled.span<BadgeProps>(
  ({ secondary, theme }) => css`
    background-color: ${secondary
      ? theme.brandColors.secondary.light
      : theme.brandColors.primary.light};
    border-radius: 24px;
    color: ${theme.textColor.primary};
    font-size: 16px;
    font-weight: 700;
    height: fit-content;
    padding: 6px 12px;
    width: fit-content;

    a:has(> &) {
      text-decoration: none;
    }
  `
);
