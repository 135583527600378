import { useEffect, useState } from 'react';

export const useBodyScrollBlock = () => {
  const [blockScroll, setBlockScroll] = useState(false);
  const [originalStyle, setOriginalStyle] = useState('');
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    setOriginalStyle(String(document.body.getAttribute('style')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!blockScroll) {
      return;
    }

    setScrollTop(document.documentElement.scrollTop);
  }, [blockScroll]);

  useEffect(() => {
    document.body.setAttribute(
      'style',
      blockScroll
        ? `
          position: fixed;
          top: ${scrollTop * -1}px;
          bottom: 0;
          left: 0;
          right: 0;
        `
        : originalStyle
    );

    if (!blockScroll) {
      window.scrollTo(0, scrollTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockScroll, scrollTop]);

  return (blockScroll: boolean) => {
    setBlockScroll(blockScroll);
  };
};
