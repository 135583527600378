import { gql } from 'graphql-request';

export const caseBlockFragment = gql`
  fragment _pageFragment on Page {
    _id
    i18n_lang
    parentPage {
      i18n_lang
      ...slug
      type
    }
    ...slug
    title
    type
  }

  fragment caseBlock on CaseBlock {
    __typename
    _key
    contentRaw
    services {
      ..._pageFragment
    }
    tools {
      ..._pageFragment
    }
  }
`;
