import { gql } from 'graphql-request';

export const heroBlockFragment = gql`
  fragment heroBlock on HeroBlock {
    __typename
    _key
    align
    alternate
    callToAction
    callToActionPage {
      i18n_lang
      parentPage {
        i18n_lang
        slug {
          current
        }
        type
      }
      slug {
        current
      }
      type
    }
    callToActionUrl
    heading
    image {
      alt
      asset {
        url
        metadata {
          lqip
        }
      }
    }
    intro
    outro
    swapWords
    text
    video {
      asset {
        url
      }
    }
  }
`;
