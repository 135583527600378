import styled, { css } from 'styled-components';

export const Label = styled.label(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    display: grid;
    font-size: 24px;
    gap: 16px;
    height: 100%;
    justify-items: center;
    padding: 16px;

    &:has(img) {
      align-content: start;
      padding: 56px 16px 40px;
    }

    &:has(input:focus) {
      border-color: ${theme.brandColors.primary.main};
    }

    &:has(input:checked),
    &:hover {
      border-color: ${theme.brandColors.secondary.main};
    }
  `
);

export const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: none;
  height: 1px;
  position: absolute;
  width: 1px;

  &:focus {
    outline: none;
  }
`;
