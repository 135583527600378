import { useContext } from 'react';
import * as Styled from './NavigationItem.styled';
import * as Types from './NavigationItem.types';
import { NavigationContext } from '../navigation-context-provider';
import { NavigationExpansion } from '../navigation-expansion';

export const NavigationItem = ({
  _key,
  active,
  cards,
  children,
  expandable,
  title,
}: Types.NavigationItemProps) => {
  const [activeId, setActiveId] = useContext(NavigationContext).expansion;

  return (
    <Styled.NavigationItem>
      {children}
      {expandable && (
        <>
          <Styled.ChevronButton
            aria-expanded={active}
            aria-label={`Open ${title?.replace(/\*/g, '')}`}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setActiveId(_key);
              }

              if (activeId === _key && event.key === 'Enter') {
                setActiveId(null);
              }
            }}
            onMouseOver={() => setActiveId(_key)}
          >
            <Styled.ChevronDownDoodle active={active} />
          </Styled.ChevronButton>

          {cards?.length && <NavigationExpansion _key={_key} cards={cards} />}
        </>
      )}
    </Styled.NavigationItem>
  );
};
