import { gql } from 'graphql-request';

export const textImageBlockFragment = gql`
  fragment textImageBlock on TextImageBlock {
    __typename
    _key
    heading
    image {
      alt
      ...asset
    }
    position
    text
  }
`;
