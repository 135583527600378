import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    :root {
      --spacing: ${theme.spacing.mobile};

      @media (min-width: ${theme.breakpoints.medium}) {
        --spacing: ${theme.spacing.desktop};
      }
    }

    ::selection {
      background-color: ${theme.brandColors.primary.main};
      color: #ffffff;
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    html {
      -webkit-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    html,
    body {
      font-family: ${theme.fontFamily.primary};
    }

    body {
      background-color: ${theme.backgroundColor.primary};
    }

    a {
      color: #2ab0b9;

      &:hover {
        text-decoration: none;
      }

      &:focus-visible {
        outline: 3px solid ${theme.brandColors.primary.main};
      }
    }

    button:focus-visible {
      outline: 3px solid ${theme.brandColors.primary.main};
    }

    em {
      font-style: normal;
      font-weight: bold;
      text-decoration: underline;
    }
  `
);
