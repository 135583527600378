import styled from 'styled-components';

export const Author = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  height: fit-content;
`;

export const avatarMediaCondition = `
  32px
`;

export const Avatar = styled.div`
  align-self: center;
  height: 32px;
  position: relative;
  width: 32px;

  img {
    border-radius: 50%;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: rows;
`;

export const Name = styled.div`
  align-self: center;
  font-weight: bold;
  padding-left: 16px;
`;

export const Position = styled.div`
  padding-left: 16px;
`;
