import styled, { css } from 'styled-components';

import { ReactComponent as CrossSvg } from '@/images/cross.svg';
import { ReactComponent as NavigationIconSvg } from '@/images/navigation-icon.svg';

export const NavigationMobileToggle = styled.button(
  ({ theme }) => css`
    background-color: transparent;
    border: none;
    height: 24px;
    margin-left: var(--spacing);
    width: 24px;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
    }
  `
);

export const CloseIcon = styled(CrossSvg)`
  height: 24px;
  margin-top: 1px;
  width: 24px;
`;

export const NavigationIcon = styled(NavigationIconSvg)`
  height: 14px;
  margin-top: 4px;
  width: 22px;
`;
