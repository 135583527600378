import { gql } from 'graphql-request';

import { configurationFragment } from '../fragments/configuration-fragment';

export const configurationQuery = gql`
  ${configurationFragment}

  query Configuration {
    configuration: allConfiguration {
      ...configuration
    }
  }
`;
