import styled, { css } from 'styled-components';

import OrnamentDoodleSvg from '@/images/ornament-doodle.svg';
import { ReactComponent as ChevronDownDoodleSvg } from '@/images/chevron-doodle-down.svg';

export const NavigationItem = styled.li(
  ({ theme }) => css`
    cursor: pointer;
    font-size: 24px;
    font-weight: normal;
    overflow: hidden;
    padding-left: var(--spacing);
    position: relative;

    @media (min-width: ${theme.breakpoints.medium}) {
      align-items: center;
      display: grid;
      font-size: 18px;
      grid-auto-flow: column;
      overflow: visible;
      padding-left: 0;
      position: static;
    }

    em {
      font-weight: bold;
      position: relative;
      text-decoration: none;

      &:after {
        content: url('${OrnamentDoodleSvg}');
        display: block;
        height: 12px;
        left: 0;
        margin-left: 11px;
        margin-top: -16px;
        position: absolute;
        top: 0;
        width: 23px;
      }
    }
  `
);

export const ChevronButton = styled.button(
  ({ theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    border: none;
    margin-left: 8px;
    position: absolute;
    right: var(--spacing);
    top: 26px;

    @media (min-width: ${theme.breakpoints.medium}) {
      position: static;
    }
  `
);

export const ChevronDownDoodle = styled(ChevronDownDoodleSvg).withConfig({
  shouldForwardProp: (prop: string) => !['active'].includes(prop),
})(
  ({ active, theme }) => css`
    display: inline-block;
    fill: ${active ? theme.brandColors.primary.main : theme.textColor.primary};
    height: 13px;
    transform: rotate(${active ? '-180deg' : '0'});
    transition: transform 0.2s ease-in-out;
    width: 14px;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 8px;
      width: 9px;
    }
  `
);
