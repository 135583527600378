import { gql } from 'graphql-request';

export const articleBlockFragment = gql`
  fragment articleBlock on ArticleBlock {
    __typename
    _key
    contentRaw
    tags
  }
`;
