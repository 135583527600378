import { gql } from 'graphql-request';

export const pageLinkFragment = gql`
  fragment pageLink on Page {
    i18n_lang
    parentPage {
      i18n_lang
      ...slug
      type
    }
    ...slug
    type
  }
`;
