import styled, { css } from 'styled-components';

import { Button, Text } from '@humanoids/ui';

import { ReactComponent as ManHoldingSignIllustrationBase } from '../../public/images/man-holding-sign-illustration.svg';
import { ReactComponent as DoodlesLeftBase } from '../../public/images/doodles-left.svg';
import { ReactComponent as DoodlesRightBase } from '../../public/images/doodles-right.svg';

export const InquiryCta = styled(Button)(
  ({ theme }) => css`
    align-items: center;
    border-radius: 0;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0 -2px -2px -2px;
    padding: 8px 0 8px 42px;
    position: fixed;
    right: -2px;
    width: calc(100vw + 4px);
    z-index: 90;

    @media (min-width: ${theme.breakpoints.medium}) {
      border-radius: 16px;
      bottom: var(--spacing);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
      margin: 0 0 0 auto;
      padding: 16px;
      right: var(--spacing);
      text-align: left;
      width: max-content;
    }

    & > ${Text} {
      align-items: center;
      display: grid;
      font-size: 14px;
      font-weight: normal;
      gap: 4px;
      grid-template-columns: 1fr 1fr min-content;
      grid-template-rows: 1fr;
      text-align: right;
      width: inherit;

      > button:not(${CloseButton}) {
        grid-row: auto;
        padding: 0;

        @media (max-width: ${theme.breakpoints.medium}) {
          background-color: transparent;
          border-radius: 0;
          color: ${theme.brandColors.primary.dark};
          text-decoration: underline;
        }

        ${Text} {
          font-size: 14px;
        }

        svg {
          display: none;
        }
      }

      @media (min-width: ${theme.breakpoints.medium}) {
        font-size: 16px;
        gap: 16px;
        grid-template-columns: 1fr repeat(2, min-content);
        grid-template-rows: min-content 1fr;
        text-align: left;

        > button:not(${CloseButton}) {
          grid-row: 2;
          padding: 12px 18px;

          ${Text} {
            font-size: 16px;
          }
        }
      }
    }

    > svg {
      display: none;
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      footer:has(~ &) {
        padding-bottom: calc(var(--spacing) + 44px + 2px);
      }
    }
  `
);

export const DoodlesLeft = styled(DoodlesLeftBase)(
  ({ theme }) => css`
    bottom: 0;
    left: 14px;
    position: absolute;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
    }
  `
);

export const DoodlesRight = styled(DoodlesRightBase)(
  ({ theme }) => css`
    bottom: 0;
    position: absolute;
    right: 40px;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
    }
  `
);

export const Desktop = styled.span(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: inline;
    }
  `
);

export const Mobile = styled.span(
  ({ theme }) => css`
    display: inline;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
    }
  `
);

export const ManHoldingSignIllustration = styled(
  ManHoldingSignIllustrationBase
)(
  ({ theme }) => css`
    display: none;
    grid-column: 2;
    grid-row: 1 / span 2;
    height: 136px;
    margin-bottom: -24px;
    transition: transform 0.2s ease-in;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: block;
    }
  `
);

export const CloseButton = styled(Button)(
  ({ theme }) => css`
    height: 44px;
    padding: 0;
    width: 44px;

    a:has(&):hover & {
      background-color: transparent;
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: -48px -8px -24px -24px;

      a:has(&):hover & {
        background-color: transparent;

        &:hover {
          background-color: hsl(0, 0%, 89%);
        }
      }

      a:has(&:hover) button:first-of-type {
        background-color: ${theme.brandColors.primary.light};
      }
    }

    span {
      align-items: center;
      display: grid;
      gap: 4px;
      grid-auto-flow: column;
      margin: 0 auto;
    }
  `
);
