import { gql } from 'graphql-request';

export const headingBlockFragment = gql`
  fragment headingBlock on HeadingBlock {
    __typename
    _key
    align
    id {
      current
    }
    level
    standalone
    title
  }
`;
