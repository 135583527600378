import { gql } from 'graphql-request';

export const imagesBlockFragment = gql`
  fragment imagesBlock on ImagesBlock {
    __typename
    _key
    images {
      _key
      description
      image {
        alt
        asset {
          url
          metadata {
            lqip
          }
        }
      }
      title
    }
  }
`;
