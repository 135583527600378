import Image from 'next/image';

import * as Styled from './Author.styled';

interface AuthorProps {
  /* The `className` prop is required to allow extending this styled-component */
  className?: string;
  avatar: string;
  name: string;
  position?: string;
}

export const Author = ({ avatar, className, name, position }: AuthorProps) => (
  <Styled.Author className={className}>
    <Styled.Avatar>
      <Image alt={name} fill sizes={Styled.avatarMediaCondition} src={avatar} />
    </Styled.Avatar>
    <Styled.Wrapper>
      <Styled.Name>{name}</Styled.Name>
      {position && <Styled.Position>{position}</Styled.Position>}
    </Styled.Wrapper>
  </Styled.Author>
);
