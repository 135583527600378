import { useEffect } from 'react';

export const useTrueViewportHeight = () => {
  const setViewportHeight = () => {
    const trueVh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--true-vh', `${trueVh}px`);
  };

  useEffect(() => {
    setViewportHeight();

    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
};
