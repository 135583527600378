import styled, { css } from 'styled-components';

interface NavigationMobileProps {
  offsetTop: number;
  isVisible: boolean;
}

interface NavigationItemLinkProps {
  active?: boolean;
}

export const NavigationMobile = styled.ul<NavigationMobileProps>(
  ({ offsetTop, isVisible, theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    height: calc(var(--true-vh) * 100 - ${offsetTop}px - 68px + 1px);
    overflow-y: scroll;
    overscroll-behavior: contain;
    list-style-type: none;
    margin-top: ${isVisible ? '0' : '-76px'};
    position: fixed;
    top: calc(68px + ${offsetTop}px);
    transform: translateY(${isVisible ? '0' : '-120%'});
    transition: transform 0.2s ease-in-out;
    width: 100vw;
    -webkit-overflow-scrolling: touch;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: none;
      padding-left: 0;
    }
  `
);

export const NavigationItemLink = styled.a<NavigationItemLinkProps>(
  ({ active, theme }) => css`
    color: ${active ? theme.brandColors.primary.main : theme.textColor.primary};
    display: block;
    padding: 16px 0;
    text-decoration: none;
  `
);

interface CardLinksProps {
  active: boolean;
  cardLinksLength: number;
}

export const CardLinks = styled.ul<CardLinksProps>(
  ({ active, cardLinksLength, theme }) => css`
    border-top: 1px solid ${theme.backgroundColor.quaternary};
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    list-style-type: none;
    max-height: ${active ? `calc(1px + ${cardLinksLength} * 56px)` : '0'};
    transition: max-height linear 0.2s;
  `
);

export const CardLink = styled.a(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.backgroundColor.quaternary};
    color: ${theme.textColor.primary};
    display: block;
    font-size: 18px;
    padding: 16px 0;
    text-decoration: none;
  `
);
