import { escapeHtml } from '@humanoids/ui';

interface CharacterMap {
  [character: string]: string;
}

const characterMap: CharacterMap = {
  '*': 'strong',
  _: 'em',
  '==': 'mark',
};

export interface VariablesInterface {
  [key: string]: string;
}

export const parseMarkdown = (
  text: string,
  variables: VariablesInterface | undefined = {}
) => {
  const characters = Object.keys(characterMap)
    .map((character) => character.replace(/([*|=])/g, '\\$1'))
    .join('|');

  return escapeHtml(text)
    ?.replace(
      new RegExp(`(${characters})(.+?)(${characters})`, 'g'),
      (match, p1, p2) => {
        return `<${characterMap[p1]}>${p2}</${characterMap[p1]}>`;
      }
    )
    .replace(/\[([^[]+)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
    .replace(/{{[\s]*(\w+)[\s]*}}/g, (_, key) => variables[key]);
};
