import { gql } from 'graphql-request';

export const teamBlockFragment = gql`
  fragment teamBlock on TeamBlock {
    __typename
    _key
    compact
    teamMembers {
      _id
      avatar {
        alt
        asset {
          url
          metadata {
            lqip
          }
        }
        crop {
          bottom
          left
          right
          top
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      biography
      name
      position
      slug {
        current
      }
    }
  }
`;
