import { gql } from 'graphql-request';

export const caseSelectionBlockFragment = gql`
  fragment caseSelectionBlock on CaseSelectionBlock {
    __typename
    _key
    cases {
      _id
      blocks {
        ...caseBlock
      }
      client
      description
      i18n_lang
      image {
        alt
        ...asset
      }
      ...slug
      type
      title
    }
  }
`;
