import styled, { css } from 'styled-components';

import * as Types from './Card.types';

export const Card = styled.div<Omit<Types.CardProps, 'children'>>(
  ({ size, theme }) => css`
    background-color: ${theme.backgroundColor.contrast};
    display: grid;
    gap: 24px;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    padding: ${size === 'small' ? '24px' : 'var(--spacing)'};

    p {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  `
);
