import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
  backgroundColor: {
    contrast: '#FFFFFF',
    primary: 'hsl(60, 10%, 96%)',
    secondary: '#2F2F2F',
    tertiary: '#222222',
    quaternary: '#EAEAEA',
  },
  brandColors: {
    primary: {
      light: '#D19DF0',
      main: '#AE71D3',
      dark: '#9358b7',
    },
    secondary: {
      light: '#84CED3',
      main: '#009AA5',
      dark: '#007B86',
    },
  },
  breakpoints: {
    large: '1200px',
    medium: '933px',
  },
  fontFamily: {
    primary: 'Maison Neue',
  },
  gradient: `linear-gradient(
    91.64deg,
    #4f88ef 0%,
    #906ef0 21.35%,
    #b378d7 39.58%,
    #9384d1 57.81%,
    #6086ce 77.08%,
    #0eb696 100%
  )`,
  spacing: {
    desktop: '32px',
    mobile: '24px',
  },
  /**
   * TODO: Consider to change name, as it's also being used by border and more
   */
  textColor: {
    contrast: '#FFFFFF',
    primary: '#222222',
    secondary: '#D8D8D8',
  },
};

interface ThemeProvider {
  children: ReactNode;
}

export const HumThemeProvider = ({ children }: ThemeProvider) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
