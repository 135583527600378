import styled, { css } from 'styled-components';

export const Input = styled.input(
  ({ theme }) => css`
    border-radius: 16px;
    border: 2px solid ${theme.brandColors.secondary.dark};
    font-size: 16px;
    height: 56px;
    margin-top: 4px;
    padding: 8px 16px;
    width: 100%;

    &:focus {
      border-color: ${theme.brandColors.primary.dark};
      outline: none;
    }

    &:user-invalid {
      border-color: indianred;
    }
  `
);
