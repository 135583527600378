import styled, { css } from 'styled-components';

export const Code = styled.div(
  ({ theme }) => css`
    margin: 0 calc(0px - var(--spacing));
    width: 100vw;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: initial;
      width: 100%;
    }

    pre {
      overflow: scroll;
      padding: 16px;
    }

    pre div {
      line-height: 20px;
    }

    pre span {
      font-size: 16px;
    }
  `
);
