import { gql } from 'graphql-request';

export const picturesBlockFragment = gql`
  fragment picturesBlock on PicturesBlock {
    __typename
    _key
    pictures {
      _key
      caption
      originalAspectRatio
      picture {
        alt
        asset {
          metadata {
            dimensions {
              height
              width
            }
            lqip
          }
          url
        }
      }
    }
  }
`;
