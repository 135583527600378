import { useIntl } from 'react-intl';

import { Button, parseMarkdown, Text } from '@humanoids/ui';

import { InquiryDialogAnswer } from './InquiryDialogAnswer';
import { InquiryDialogAnswers } from './InquiryDialogAnswers';

import * as Styled from './InquiryDialogProjectDetails.styled';

export const InquiryDialogProjectDetails = ({
  budget,
  onFinalize,
  onProceed,
  planning,
  variables,
}) => {
  const intl = useIntl();

  return (
    <>
      <Text
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryProjectDetailsIntro',
            }),
            variables
          ),
        }}
        align="center"
        large
      />
      <Text
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryProjectDetailsBudget',
            })
          ),
        }}
        small
      />
      <InquiryDialogAnswers>
        {budget.map((answer, i) => (
          <InquiryDialogAnswer
            id={`budget-${i}`}
            key={answer}
            name="budget"
            onSelect={onProceed}
            value={answer}
          >
            <Styled.Text
              align="center"
              as="span"
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(answer),
              }}
              small
            />
          </InquiryDialogAnswer>
        ))}
      </InquiryDialogAnswers>
      <Text
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryProjectDetailsPlanning',
            })
          ),
        }}
        small
      />
      <InquiryDialogAnswers>
        {planning.map((answer, i) => (
          <InquiryDialogAnswer
            id={`planning-${i}`}
            key={answer}
            name="planning"
            onSelect={onProceed}
            value={answer}
          >
            <Styled.Text
              align="center"
              as="span"
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(answer),
              }}
              small
            />
          </InquiryDialogAnswer>
        ))}
      </InquiryDialogAnswers>
      <Button onClick={onFinalize} tertiary>
        {intl.formatMessage({
          id: 'finish',
        })}
      </Button>
    </>
  );
};
