import { gql } from 'graphql-request';

export const textBlockFragment = gql`
  fragment textBlock on TextBlock {
    __typename
    _key
    align
    size
    standalone
    text
  }
`;
