import { gql } from 'graphql-request';

import {
  assetFragment,
  caseBlockFragment,
  configurationFragment,
  pageLinkFragment,
  slugFragment,
} from '../fragments';
import { translationMetadataFragment } from '../fragments/translation-metadata-fragment';

export const appQuery = gql`
  ${assetFragment}
  ${caseBlockFragment}
  ${configurationFragment}
  ${pageLinkFragment}
  ${slugFragment}
  ${translationMetadataFragment}

  query App($locale: String!) {
    articles: allPage(
      where: { i18n_lang: { eq: $locale }, type: { eq: "article" } }
      sort: { publicationDateTime: DESC }
    ) {
      _id
      author {
        avatar {
          asset {
            url
          }
          crop {
            bottom
            left
            right
            top
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        name
      }
      i18n_lang
      image {
        alt
        ...asset
      }
      publicationDateTime
      ...slug
      title
      type
    }

    cases: allPage(
      where: {
        i18n_lang: { eq: $locale }
        type: { eq: "case" }
        private: { neq: true }
      }
      sort: { orderRank: ASC }
    ) {
      _id
      blocks {
        ...caseBlock
      }
      client
      description
      i18n_lang
      image {
        alt
        ...asset
      }
      orderRank
      publicationDateTime
      ...slug
      subtitle
      title
      type
    }

    locations: allLocation {
      _id
      address {
        en
        nl
      }
      city
      country
      emailAddress
      phoneNumber
      photo {
        alt
        ...asset
      }
      postalCode
      route
      streetAddress
      title
    }

    configuration: allConfiguration {
      ...configuration
    }

    inquiry: allInquiry(
      where: { i18n_lang: { eq: $locale } }
      sort: { _key: ASC }
    ) {
      _id
      budget
      planning
      questions {
        _id
        question
        answers {
          _id
          answer
          icon {
            alt
            asset {
              url
            }
          }
        }
        conditional {
          _id
          answer
        }
      }
    }

    navigation: allNavigation(where: { i18n_lang: { eq: $locale } }) {
      navigationItems {
        _key
        align
        cards {
          _key
          alternate
          description
          links {
            _key
            page {
              ...pageLink
            }
            title
            url
          }
          page {
            ...pageLink
          }
          title
        }
        page {
          ...pageLink
        }
        title
        url
      }
    }

    stickyNotification: allStickyNotification(
      where: { i18n_lang: { eq: $locale } }
    ) {
      end
      start
      stickyNotification
    }

    translationMetadata: allTranslationMetadata {
      schemaTypes
      translations {
        value {
          ...translationMetadata
        }
      }
    }

    translations: allTranslation {
      key
      translations {
        en
        nl
      }
    }
  }
`;
