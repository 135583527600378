import { parseMarkdown } from '@humanoids/ui';

import { StickyNotificationType } from '../../services/graphql';

import * as Styled from './StickyNotification.styled';

export const StickyNotification = ({
  end,
  start,
  stickyNotification,
}: StickyNotificationType) => {
  if (
    !stickyNotification ||
    (end && new Date(end) < new Date()) ||
    (start && new Date(start) > new Date())
  ) {
    return null;
  }

  return (
    <Styled.StickyNotification
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(stickyNotification),
      }}
    />
  );
};
