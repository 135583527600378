import { gql } from 'graphql-request';

import { configurationFragment, pageLinkFragment } from '../fragments';

export const pathsQuery = gql`
  ${configurationFragment}
  ${pageLinkFragment}

  query Paths {
    configuration: allConfiguration {
      ...configuration
    }

    pages: allPage(where: { private: { neq: true } }) {
      ...pageLink
    }
  }
`;
