import styled, { css } from 'styled-components';

import { Button } from '@humanoids/ui';

export const InquiryDialog = styled.dialog<{ $open: boolean }>(
  ({ theme, $open }) => css`
    background-color: ${theme.backgroundColor.primary};
    gap: var(--spacing);
    grid-auto-rows: min-content;
    height: 100%;
    padding: var(--spacing);
    position: fixed;

    &:modal {
      border: none;
      display: grid;
      max-height: 100vh;
      max-height: 100dvh;
      max-width: 100vw;
    }

    body:has(&) {
      overflow: ${$open ? 'hidden' : 'auto'};
    }

    &::backdrop {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
    }

    @media (min-width: ${theme.breakpoints.medium}) {
      border-radius: 16px;
      height: fit-content;
      margin: auto;
      overflow: visible;
      width: 100%;

      &:modal {
        max-height: 100%;
        max-width: calc(1200px - calc(4 * var(--spacing)));
      }
    }
  `
);

export const CloseButton = styled(Button)`
  margin-left: auto;

  span {
    align-items: center;
    display: grid;
    gap: 4px;
    grid-auto-flow: column;
  }
`;

export const Form = styled.form(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    height: 100%;
    justify-self: center;
    width: 100%;

    @media (min-width: ${theme.breakpoints.medium}) {
      max-width: 75%;
    }
  `
);

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;

export const ProgressBar = styled.div<{ progress: number }>(
  ({ progress, theme }) => css`
    background-color: ${theme.backgroundColor.quaternary};
    display: block;
    height: 4px;
    min-width: 280px;

    &:before {
      content: '';
      display: block;
      width: calc(${progress} * 100%);
      height: 4px;
      background-color: ${theme.brandColors.secondary.dark};
    }
  `
);
