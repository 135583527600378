import { gql } from 'graphql-request';

export const configurationFragment = gql`
  fragment configuration on Configuration {
    articles {
      i18n_lang
      slug {
        current
      }
    }
    cases {
      i18n_lang
      slug {
        current
      }
    }
    homepage {
      i18n_lang
      slug {
        current
      }
    }
    socialPlatform {
      platform
      logo {
        asset {
          url
        }
      }
      url
    }
    i18n_lang
    name
  }
`;
