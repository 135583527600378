import { useContext } from 'react';

import { NavigationCard } from '../navigation-card';
import { NavigationContext } from '../navigation-context-provider';

import * as Styled from './NavigationExpansion.styled';
import * as Types from './NavigationExpansion.types';

export const NavigationExpansion = ({
  _key,
  cards,
}: Types.NavigationExpansionProps) => {
  const [activeId] = useContext(NavigationContext).expansion;

  return (
    <Styled.NavigationExpansion
      isExpanded={activeId !== null}
      isVisible={activeId === _key}
      key={_key}
      role="dialog"
    >
      <Styled.Container>
        {cards.map((card) => (
          <NavigationCard card={card} key={card._key} />
        ))}
      </Styled.Container>
    </Styled.NavigationExpansion>
  );
};
