import { useContext } from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/compat/router';
import { useIntl } from 'react-intl';

import { NavigationContext } from '../navigation-context-provider';

import * as Styled from './NavigationSecondary.styled';

export const NavigationSecondary = () => {
  const [, setIsVisible] = useContext(NavigationContext).mobile;
  const pathname = usePathname();
  const router = useRouter();

  const intl = useIntl();

  const handleLocaleChange = (locale: string) => {
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
    router.push('/', '/', { locale });
  };

  return (
    <Styled.NavigationSecondary>
      <Styled.Container>
        <Styled.NavigationItem
          href="#"
          onClick={() => {
            router.push(`${pathname}?inquiry=true`);
            setIsVisible(false);
          }}
          prefetch={false}
        >
          {intl.formatMessage({
            id: 'startInquiry',
          })}
        </Styled.NavigationItem>
        <Styled.NavigationItem
          href="/contact"
          onClick={() => setIsVisible(false)}
          prefetch={false}
        >
          Contact
        </Styled.NavigationItem>
        {router.locales
          .filter((locale) => locale !== router.locale)
          .map((locale) => (
            <Styled.NavigationItem
              href="/"
              key={locale}
              lang={locale}
              onClick={(event) => {
                event.preventDefault();
                handleLocaleChange(locale);
                setIsVisible(false);
              }}
              prefetch={false}
            >
              {intl.formatMessage({
                id: `alternativeLanguage${locale
                  .charAt(0)
                  .toUpperCase()}${locale.slice(1)}`,
              })}
            </Styled.NavigationItem>
          ))}
      </Styled.Container>
    </Styled.NavigationSecondary>
  );
};
