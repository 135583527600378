import { gql } from 'graphql-request';

export const translationMetadataFragment = gql`
  fragment translationMetadata on Page {
    _id
    i18n_lang
    parentPage {
      _id
    }
    slug {
      current
    }
    type
  }
`;
