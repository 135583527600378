import styled, { css } from 'styled-components';

import * as Types from './Heading.types';

const HeadingBase = styled.h1<Types.StyledHeadingProps>(
  ({ align, theme }) => css`
    color: ${theme.textColor.primary};
    text-align: ${align ?? 'left'};
    white-space: pre-line;
  `
);

export const HeadingPrimary = styled(HeadingBase)(
  ({ theme }) => css`
    font-size: 48px;
    line-height: 68px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 72px;
      line-height: 100px;
    }
  `
);

export const HeadingSecondary = styled(HeadingBase)(
  ({ theme }) => css`
    font-size: 32px;
    line-height: 52px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 48px;
      line-height: 68px;
    }
  `
);

export const HeadingTertiary = styled(HeadingBase)(
  ({ theme }) => css`
    font-size: 24px;
    line-height: 38px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 32px;
      line-height: 48px;
    }
  `
);

export const HeadingQuaternary = styled(HeadingBase)(
  ({ theme }) => css`
    font-size: 20px;
    line-height: 32px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 24px;
      line-height: 38px;
    }
  `
);

export const HeadingQuinary = styled(HeadingBase)(
  ({ theme }) => css`
    font-size: 18px;
    line-height: 28px;

    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: 20px;
      line-height: 32px;
    }
  `
);
