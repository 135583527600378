import styled, { css } from 'styled-components';

export const StickyNotification = styled.div(
  ({ theme }) => css`
    background-color: #2f2f2f;
    color: #d8d8d8;
    font-size: 16px;
    letter-spacing: -0.5px;
    max-height: 76px;
    padding: 16px var(--spacing);
    position: relative;
    text-align: center;
    // Required due to Safari z-index bug when scrolling:
    transform: translateZ(1px);
    width: 100vw;
    z-index: 110;

    @media (min-width: ${theme.breakpoints.medium}) {
      letter-spacing: normal;
    }

    a {
      color: inherit;
    }
  `
);
