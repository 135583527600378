import Image from 'next/image';
import { useIntl } from 'react-intl';

import { Button, Text, VariablesInterface, parseMarkdown } from '@humanoids/ui';

interface InquiryDialogCompleteProps {
  onClose: () => void;
  variables: VariablesInterface;
}

export const InquiryDialogComplete = ({
  onClose,
  variables,
}: InquiryDialogCompleteProps) => {
  const intl = useIntl();

  return (
    <>
      <Text
        align="center"
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryCompleteIntro',
            })
          ),
        }}
        large
      />
      <Image
        alt="fireworks doodle"
        src="/images/fireworks-doodle.svg"
        height={50}
        width={55}
      />
      <Text
        align="center"
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryCompleteConfirmation',
            }),
            variables
          ),
        }}
        large
      />
      <Button onClick={onClose} tertiary>
        {intl.formatMessage({
          id: 'inquiryCompleteClose',
        })}
      </Button>
    </>
  );
};
